import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StocksComponent } from './stocks/stocks.component';

const routes: Routes = [
  {
    path: 'stocks',
    component: StocksComponent
  },
  {
    path: 'receptions',
    loadComponent: () =>
      import('./receptions/pages/receptions.component')
        .then(m => m.ReceptionsComponent)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
