import { StocksModule } from './stocks/stocks.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';


@NgModule({
  imports: [
    CommonModule,
    ReportsRoutingModule,
    StocksModule
  ]
})
export class ReportsModule { }
